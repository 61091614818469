<div class="app-markdown-content">
  <ng-container>
    <div *ngIf="loading" class="loading">
      <mat-spinner diameter="50"></mat-spinner>
    </div>
    <div *ngIf="!readOnly" class="toolbar">
      <mat-form-field *ngIf="noteTemplates.length && editionMode" class="mat-form-field">
        <mat-label>Note Template</mat-label>
        <mat-select (selectionChange)="changeTemplate($event)">
          <mat-option *ngFor="let noteTemplate of noteTemplates" value="{{ noteTemplate.templateUrl }}"
            >{{ noteTemplate.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <span class="spacer">&nbsp;</span>
      <ng-container *ngIf="editionMode && attachment !== attachmentBase">
        <span style="color: red">Non sauvegardé</span>
      </ng-container>
      <button
        (click)="validateNote()"
        *ngIf="editionMode"
        [disabled]="attachment === attachmentBase"
        class="square save"
        matTooltip="Enregistrer"
      >
        <span class="material-symbols-outlined">save</span>
      </button>
      <button
        (click)="cancelEditor()"
        *ngIf="editionMode"
        class="square cancel"
        matTooltip="{{ 'COMMON.MODALS.NOTE.TOOLTIP.CANCEL' | translate }}"
      >
        <span class="material-symbols-outlined">close</span>
      </button>
      <button
        (click)="open()"
        *ngIf="!editionMode && attachments.note && attachments.noteFile"
        class="square see"
        matTooltip="{{ 'COMMON.MODALS.NOTE.TOOLTIP.SEE' | translate }}"
      >
        <span class="material-symbols-outlined">find_in_page</span>
      </button>
      <button
        (click)="editionModeToggle()"
        *ngIf="!editionMode && attachments.note && attachments.noteFile"
        class="square edit"
        matTooltip="{{ 'COMMON.MODALS.NOTE.TOOLTIP.EDIT' | translate }}"
      >
        <span class="material-symbols-outlined">edit</span>
      </button>
      <button
        (click)="deleteNote()"
        *ngIf="!editionMode && attachments.noteFile && attachments.noteFile.url"
        class="square add"
        matTooltip="{{ 'COMMON.MODALS.NOTE.TOOLTIP.DELETE' | translate }}"
      >
        <span class="material-symbols-outlined">delete_forever</span>
      </button>
    </div>
    <ng-container *ngIf="editionMode">
      <form class="markdownForm" name="markdownForm">
        <md-editor
          #mdEditor
          [(ngModel)]="attachment"
          (ngModelChange)="onModelChange($event)"
          [className]="'markdownEditor'"
          [height]="'300px'"
          [options]="options"
          mode="editor"
          name="Content"
        >
        </md-editor>
      </form>
    </ng-container>
    <ng-container *ngIf="!editionMode && attachments.note && attachments.noteFile">
      <ngx-md
        #markdownPreview
        (click)="toggleShowMore()"
        [data]="removeMarkdownLinks(attachments.note)"
        [ngClass]="{ smallPreview: !showReadMore }"
        class="attachment-preview-item"
      ></ngx-md>
    </ng-container>
    <ng-container *ngIf="!editionMode && !attachments.note && !attachments.noteFile">
      <div (click)="!readOnly && editionModeToggle()" class="textarea">
        <div class="no-attachment-content" [ngClass]="{ required: !readOnly && required && attachments && attachments.isEmpty() }">
          <span class="material-symbols-outlined iconsize">shoppingmode</span>
          <span>{{ (readOnly ? 'COMMON.MODALS.NOTE.EMPTY_MESSAGE_READONLY' : 'COMMON.MODALS.NOTE.EMPTY_MESSAGE') | translate }}</span>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!editionMode && !attachments.note && attachments.noteFile">
      <div (click)="!readOnly && editionModeToggle()" class="textarea">
        <div class="no-attachment-content" [ngClass]="{ required: !readOnly && required && attachments && attachments.isEmpty() }">
          <span class="material-symbols-outlined iconsize">shoppingmode</span>
          <span>{{ (readOnly ? 'COMMON.MODALS.NOTE.EMPTY_MESSAGE_READONLY' : 'COMMON.MODALS.NOTE.EMPTY_TEXT') | translate }}</span>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
