import { Component, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslatePipe } from '../../../common/pipes/translate/translate.pipe';
import { OclEventGroup } from '../../models/ocl-event-group.model';
import { OclLogBookGroup } from '../../models/ocl-logbook-group.model';
import { OclDecisionGroup } from '../../models/ocl-decision-group.model';
import { OclGroupService, OclGroupType } from '../../services/ocl-group-service/ocl-group.service';
import { ModuleConfigService } from '../../../common/services/module-config/module-config.service';
import { OclGlobalInstructionGroup } from '../../models/ocl-global-instruction-group.model';
import { filter, find, map, reduce } from 'lodash';
import { OclGroup } from '../../models/ocl-group.model';
import { EclLogbook } from '../../../ecl/models/ecl-logbook';
import { EclSummaryService } from '../../../ecl/services/ecl-summary-service/ecl-summary-service';
import { EclLogbookGroup } from '../../../ecl/models/ecl-logbook-group.model';
import { EclCrisisStoreManager } from '../../../ecl/store/crisis/crisis.store-manager';
import { take } from 'rxjs/operators';
import { RolesService } from '../../../common/services/roles.service';

@Component({
  selector: 'app-ocl-group-modal',
  templateUrl: './ocl-group-modal.component.html',
  styleUrls: ['./ocl-group-modal.component.scss'],
  providers: [TranslatePipe],
})
export class OclGroupModalComponent {
  public infoMsgTitle = '';
  public filteredTitleList: any[] = [];
  public isCreate: boolean;
  public isReadOnly: boolean;
  public modalTitle: string;
  public group?: any;
  public groupsFromApi?: any[];
  public loading = false;
  public form: FormGroup;
  public items: any[];
  public isItemsRequired: boolean;
  public isInSummary: boolean = false;
  public isInBriefing: boolean = false;
  public isSummaryActivated: boolean = false;
  type: OclGroupType;
  i18nkey: string;
  public addButtonTrad = 'DASHBOARD.MODAL.ADD_BUTTON';
  public updateButtonTrad = 'DASHBOARD.MODAL.UPDATE_BUTTON';
  protected readonly RolesService = RolesService;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private translatePipe: TranslatePipe,
    private groupService: OclGroupService,
    public dialogRef: MatDialogRef<OclGroupModalComponent>,
    public moduleConfig: ModuleConfigService,
    public eclSummaryService: EclSummaryService,
    public eclCrisisStoreManager: EclCrisisStoreManager,
  ) {
    this.isCreate = !data.group;
    this.isReadOnly = data.isReadOnly;

    this.group = data.group;
    this.type = data.type;
    this.groupsFromApi = data.groupsFromApi;
    this.filteredTitleList = [];
    this.items = data.events || data.logBookItems || data.decisionItems || data.globalInstructionItems;
    this.isItemsRequired = !!data.isItemsRequired;
    this.isCreate ? this.createForm() : this.setForm();
    this.isSummaryActivated = data.isSummaryActivated;

    switch (this.type) {
      case 'EVENT':
        this.i18nkey = 'EVENTS';
        break;
      case 'LOGBOOK':
        this.i18nkey = 'LOGBOOK';
        break;
      case 'DECISION':
        this.i18nkey = 'STATUS_DECISIONS';
        break;
      case 'GLOBAL_INSTRUCTION':
        this.i18nkey = 'GLOBAL_INSTRUCTION';
        break;
    }

    this.modalTitle = this.isCreate
      ? this.translatePipe.transform(moduleConfig.config.translateKey + '.DASHBOARD.' + this.i18nkey + '.MODALS.GROUP_MODAL.CREATE_TITLE')
      : this.translatePipe.transform(moduleConfig.config.translateKey + '.DASHBOARD.' + this.i18nkey + '.MODALS.GROUP_MODAL.UPDATE_TITLE');

    this.form.controls['titleForm'].valueChanges.subscribe(value => {
      if (value.length == 0) {
        this.filteredTitleList = [];
        this.infoMsgTitle = '';
      } else {
        this.filteredTitleList = this.groupsFromApi
          .map(obj => obj.title)
          .filter(option => option && option.toLowerCase().includes(value.toLowerCase()));

        // this.titleForm.setValue(value);
        if (this.groupsFromApi.find(item => item.title === value)) {
          this.infoMsgTitle = 'Ce titre de groupe existait déja';
        } else {
          this.infoMsgTitle = '';
        }
      }
    });

    if (this.isSummaryActivated && !this.isCreate) {
      this.isInSummary = !!this.group.summary;
      if (this.isInSummary) {
        this.isInBriefing = (this.group as OclGroup<any>).summary.isDisplayInBriefing;
      }
    }
  }

  get titleForm() {
    return this.form.get('titleForm') as FormControl;
  }

  get itemsForm() {
    return this.form.get('itemsForm') as FormArray;
  }

  get summaryItemsForm() {
    return this.form.get('summaryItemsForm') as FormArray;
  }

  get anyItemSelected() {
    // helps for the display of conditioned title 'Publier en synthèse' above checkboxes next to each item
    return this.itemsForm.controls.some(ctrl => ctrl.value);
  }

  public createForm(): void {
    this.form = this.fb.group({
      titleForm: ['', [Validators.required, this.uniqueTitle()]],
      itemsForm: this.isItemsRequired ? new FormArray([], this.minNumberOfCheckBoxes()) : new FormArray([]),
      summaryItemsForm: new FormArray([]),
    });
    /*this.summaryForm = this.fb.group({
      itemsForm: new FormArray([]),
    });*/
    this.addCheckboxes();
  }

  public setForm(): void {
    this.form = this.fb.group({
      titleForm: ['', [Validators.required, this.uniqueTitle()]],
      itemsForm: this.isItemsRequired ? new FormArray([], this.minNumberOfCheckBoxes()) : new FormArray([]),
      summaryItemsForm: new FormArray([]),
    });
    /*this.summaryForm = this.fb.group({
      itemsForm: new FormArray([]),
    });*/
    //this.summaryForm = ;
    this.titleForm.setValue(this.group.title);
    this.setCheckboxes();
  }

  public uniqueTitle(): any {
    return () => {
      let aGroups = this.groupsFromApi;
      if (!this.isCreate) {
        reduce(
          aGroups,
          (groups, next) => {
            // no need to validate for own title when update
            if (next.objectId !== this.group.objectId) {
              groups.push(next);
            }
            return groups;
          },
          [],
        );
      }
      // return find(aGroups, group => group.title === title) ? { notUnique: true } : null;
      return null;
    };
  }

  public minNumberOfCheckBoxes(min = 1): any {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.value.reduce((prev, next) => (next ? prev + next : prev), 0);
      return totalSelected >= min ? null : { required: true };
    };
    return validator;
  }

  isDisableAddButton() {
    if (this.titleForm.value) {
      const listGroupTitleLowerCase = this.groupsFromApi.map(obj => (obj.title ? obj.title.trim().toLowerCase() : undefined));
      if (this.isCreate) {
        return listGroupTitleLowerCase.includes(this.titleForm.value.trim().toLowerCase());
      } else {
        return listGroupTitleLowerCase
          .filter(item => (item ? item !== this.group.title.trim().toLowerCase() : undefined))
          .includes(this.titleForm.value.trim().toLowerCase());
      }
    } else {
      return this.form.invalid;
    }
  }

  public onCreateGroup(): void {
    this.loading = true;

    let group: OclGroup<any>;

    switch (this.type) {
      case 'EVENT':
        group = new OclEventGroup();
        break;
      case 'LOGBOOK':
        group = new OclLogBookGroup();
        break;
      case 'DECISION':
        group = new OclDecisionGroup();
        break;
      case 'GLOBAL_INSTRUCTION':
        group = new OclGlobalInstructionGroup();
        break;
    }
    group.title = this.titleForm.value;
    group.items = filter(
      map(this.itemsForm.value, (checked, index) => (checked ? this.items[index] : null)),
      value => !!value,
    );

    if (this.isSummaryActivated) {
      let itemsToAddInSummary = [];
      for (let i = 0; i < this.summaryItemsForm.controls.length; i++) {
        let checkboxValue = this.summaryItemsForm.controls[i].value;
        if (checkboxValue) {
          //add logbook in logbooksInSummary
          itemsToAddInSummary.push(this.items[i]);
        }
      }

      if (group instanceof OclLogBookGroup) {
        group.logbooksInSummary = itemsToAddInSummary;
      }

      if (group instanceof OclDecisionGroup) {
        group.decisionsInSummary = itemsToAddInSummary;
      }
    }

    this.groupService
      .save(group, this.type)
      .then(groupSaved => {
        groupSaved.summary = group.summary;
        if (this.isInSummary) {
          this.eclCrisisStoreManager.$eclSelectedCrisis
            .pipe(take(1))
            .toPromise()
            .then(selectedCrisis => {
              this.eclSummaryService
                .saveNewSummaryFromLogbookOrDecision(
                  groupSaved,
                  groupSaved instanceof OclLogBookGroup ? 'GROUP_LOGBOOK' : 'GROUP_DECISION',
                  selectedCrisis,
                  this.isInBriefing,
                  this.isInSummary,
                )
                .then();
            });
        }

        this.dialogRef.close(groupSaved);
      })
      .catch(error => console.log(error))
      .finally(() => {
        this.loading = false;
      });
  }

  public onUpdateGroup(): void {
    this.loading = true;
    this.group.title = this.titleForm.value;
    this.group.items = filter(
      map(this.itemsForm.value, (checked, index) => (checked ? this.items[index] : null)),
      value => !!value,
    );

    let itemsToAddInSummary = [];
    for (let i = 0; i < this.summaryItemsForm.controls.length; i++) {
      let checkboxValue = this.summaryItemsForm.controls[i].value;
      if (checkboxValue) {
        //add logbook in logbooksInSummary
        itemsToAddInSummary.push(this.items[i]);
      }
    }

    if (this.group instanceof OclLogBookGroup) {
      this.group.logbooksInSummary = itemsToAddInSummary;
    }

    if (this.group instanceof OclDecisionGroup) {
      this.group.decisionsInSummary = itemsToAddInSummary;
    }

    // todo save logbookGroup in EclSummary

    this.groupService
      .save(this.group, this.type)
      .then(groupSaved => {
        if (this.isSummaryActivated) {
          groupSaved.summary = this.group.summary;
          this.eclCrisisStoreManager.$eclSelectedCrisis
            .pipe(take(1))
            .toPromise()
            .then(selectedCrisis => {
              this.eclSummaryService
                .saveNewSummaryFromLogbookOrDecision(
                  groupSaved,
                  groupSaved instanceof OclLogBookGroup ? 'GROUP_LOGBOOK' : 'GROUP_DECISION',
                  selectedCrisis,
                  this.isInBriefing,
                  this.isInSummary,
                )
                .then();
            });
        }
        this.dialogRef.close(groupSaved);
      })
      .catch(error => console.log(error))
      .finally(() => (this.loading = false));
  }

  public onDeleteGroup(): void {
    this.groupService
      .delete(this.group, this.type)
      .then(() => this.dialogRef.close())
      .catch(error => console.log(error))
      .finally(() => (this.loading = false));
  }

  public getAlreadyInGroups(item): any[] {
    return reduce(
      this.groupsFromApi,
      (groups, next) => {
        if (find(next.items, e => e.objectId === item.objectId) && !find(groups, group => group.objectId === next.objectId)) {
          groups.push(next);
        }
        return groups;
      },
      [],
    );
  }

  selectGroup(groupTitleSelected: any) {
    if (this.groupsFromApi.find(item => item.title === groupTitleSelected)) {
      this.group = this.groupsFromApi.find(item => item.title === groupTitleSelected);
    }
    this.isCreate = false;
    this.setForm();
    this.modalTitle = this.translatePipe.transform(
      this.moduleConfig.config.translateKey + '.DASHBOARD.' + this.i18nkey + '.MODALS.GROUP_MODAL.UPDATE_TITLE',
    );
  }

  private setCheckboxes(): void {
    this.items.forEach((e, i) => {
      const isChecked = !!find(this.group.items, el => el.objectId === e.objectId);
      const control = new FormControl(isChecked);
      if (this.isReadOnly) {
        control.disable();
      }
      (this.form.controls.itemsForm as FormArray).push(control);

      if (this.group instanceof OclLogBookGroup) {
        let isInSummaryChecked = !!find(this.group.logbooksInSummary, el => el.objectId === e.objectId);
        const summaryControl = new FormControl(isInSummaryChecked); //todo test me when general AddSummary checkbox is working
        (this.form.controls.summaryItemsForm as FormArray).push(summaryControl);
      }

      if (this.group instanceof OclDecisionGroup) {
        let isInSummaryChecked = !!find(this.group.decisionsInSummary, el => el.objectId === e.objectId);
        const summaryControl = new FormControl(isInSummaryChecked); //todo test me when general AddSummary checkbox is working
        (this.form.controls.summaryItemsForm as FormArray).push(summaryControl);
      }
    });
  }

  private addCheckboxes(): void {
    this.items.forEach((e, i) => {
      const control = new FormControl(false);
      if (this.isReadOnly) {
        control.disable();
      }
      (this.form.controls.itemsForm as FormArray).push(control);

      const summaryControl = new FormControl(false);
      (this.form.controls.summaryItemsForm as FormArray).push(summaryControl);
    });
  }
}
